::-webkit-scrollbar {
  display: none;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  scrollbar-width: none;
}

html {
  background-color: #030c1b;
}
